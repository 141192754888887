






































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import MediaExternalAccount from '@/components/media/ExternalAccount.vue';
import ExternalAccountForm from '@/components/dashboard/ExternalAccountForm.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  findAccount: (id: string) => ApiResource.Business.Account | undefined,
  listExternalAccounts: (acct: string) => ApiResource.Business.ExternalAccount[],
  listPayouts: (acct: string) => ApiResource.Business.Payout[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    ExternalAccountForm,
    MediaExternalAccount,
  },
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null as null | Error,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      findAccount: 'business/FindAccount',
      listExternalAccounts: 'business/ExternalAccounts/List',
      listPayouts: 'business/Payouts/List',
    }),
    BUSINESS(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
    PAYOUT_ACCOUNT(): ApiResource.Business.Account | undefined {
      return this.findAccount(this.BUSINESS.id);
    },
    PAYOUT_SETTINGS(): ApiResource.Business.Account['settings']['payouts'] | null {
      if (!this.PAYOUT_ACCOUNT) return null;
      if (!this.PAYOUT_ACCOUNT.settings) return null;
      if (!this.PAYOUT_ACCOUNT.settings.payouts) return null;
      return this.PAYOUT_ACCOUNT.settings.payouts;
    },
    list(): ApiResource.Business.ExternalAccount[] {
      if (!this.PAYOUT_ACCOUNT) return [];
      return this.listExternalAccounts(this.PAYOUT_ACCOUNT.id);
    },
  },
  watch: {
    BUSINESS: {
      immediate: true,
      async handler(to) {
        this.fetchExternalAccounts(to.id);
      },
    },
  },
  methods: {
    async fetchExternalAccounts(BUSINESS_ID: string) {
      this.loading = true;

      await this.$store.dispatch('business/ExternalAccounts/List', {
        BUSINESS_ID,
      }).catch((e: Error) => { this.error = e; });

      this.loading = false;
    },
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
